import multiselectMixin from "./multiselectMixin";
import pointerMixin from "./pointerMixin";
import Checkbox from "./../checkbox";
import Vue from "vue";
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll);

export default {
    name: "vue-multiselect",
    mixins: [multiselectMixin, pointerMixin],
    components: {
        checkbox: Checkbox,
    },
    props: {
        /**
         * name attribute to match optional label element
         * @default ''
         * @type {String}
         */
        name: {
            type: String,
            default: "",
        },
        /**
         * String to show when pointing to an option
         * @default 'Press enter to select'
         * @type {String}
         */
        selectLabel: {
            type: String,
            default: "Press enter to select",
        },
        /**
         * String to show when pointing to an option
         * @default 'Press enter to select'
         * @type {String}
         */
        selectGroupLabel: {
            type: String,
            default: "Press enter to select group",
        },
        /**
         * String to show next to selected option
         * @default 'Selected'
         * @type {String}
         */
        selectedLabel: {
            type: String,
            default: "Selected",
        },
        /**
         * String to show when pointing to an already selected option
         * @default 'Press enter to remove'
         * @type {String}
         */
        deselectLabel: {
            type: String,
            default: "Press enter to remove",
        },
        /**
         * String to show when pointing to an already selected option
         * @default 'Press enter to remove'
         * @type {String}
         */
        deselectGroupLabel: {
            type: String,
            default: "Press enter to deselect group",
        },
        /**
         * Decide whether to show pointer labels
         * @default true
         * @type {Boolean}
         */
        showLabels: {
            type: Boolean,
            default: true,
        },
        /**
         * Limit the display of selected options. The rest will be hidden within the limitText string.
         * @default 99999
         * @type {Integer}
         */
        limit: {
            type: Number,
            default: 99999,
        },
        /**
         * Sets maxHeight style value of the dropdown
         * @default 300
         * @type {Integer}
         */
        maxHeight: {
            type: Number,
            default: 300,
        },
        /**
         * Function that process the message shown when selected
         * elements pass the defined limit.
         * @default 'and * more'
         * @param {Int} count Number of elements more than limit
         * @type {Function}
         */
        limitText: {
            type: Function,
            default: (count) => `+${count}`,
        },
        /**
         * Set true to trigger the loading spinner.
         * @default False
         * @type {Boolean}
         */
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * Disables the multiselect if true.
         * @default false
         * @type {Boolean}
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Fixed opening direction
         * @default ''
         * @type {String}
         */
        openDirection: {
            type: String,
            default: "",
        },
        /**
         * Shows slot with message about empty options
         * @default true
         * @type {Boolean}
         */
        showNoOptions: {
            type: Boolean,
            default: true,
        },
        showNoResults: {
            type: Boolean,
            default: true,
        },
        tabindex: {
            type: Number,
            default: 0,
        },
        checkboxes: {
            type: Boolean,
            default: false,
        },
        infiniteScrollDistance: {
            type: Number,
            default: 0,
        },
        infiniteScrollDisabled: {
            type: Boolean,
            default: false,
        },
        loadingMoreFilters: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isSingleLabelVisible() {
            return (this.singleValue || this.singleValue === 0) && (!this.isOpen || !this.searchable) && !this.visibleValues.length;
        },
        isPlaceholderVisible() {
            return !this.internalValue.length && (!this.searchable || !this.isOpen);
        },
        visibleValues() {
            return this.multiple ? this.internalValue.slice(0, this.limit) : [];
        },
        singleValue() {
            return this.internalValue[0];
        },
        deselectLabelText() {
            return this.showLabels ? this.deselectLabel : "";
        },
        deselectGroupLabelText() {
            return this.showLabels ? this.deselectGroupLabel : "";
        },
        selectLabelText() {
            return this.showLabels ? this.selectLabel : "";
        },
        selectGroupLabelText() {
            return this.showLabels ? this.selectGroupLabel : "";
        },
        selectedLabelText() {
            return this.showLabels ? this.selectedLabel : "";
        },
        inputStyle() {
            if (this.multiple && this.value && this.value.length) {
                // Hide input by setting the width to 0 allowing it to receive focus
                return this.isOpen ? { width: "90%", right: "0px" } : { width: "0", position: "absolute", padding: "0" };
            } else if (this.searchable) {
                // Hide input by setting the width to 0 allowing it to receive focus
                return this.isOpen ? { width: "100%" } : { width: "0", position: "absolute", padding: "0" };
            }
            return "";
        },
        contentStyle() {
            return this.options.length ? { display: "inline-block" } : { display: "block" };
        },
        isAbove() {
            if (this.openDirection === "above" || this.openDirection === "top") {
                return true;
            } else if (this.openDirection === "below" || this.openDirection === "bottom") {
                return false;
            } else {
                return this.preferredOpenDirection === "above";
            }
        },
        showSearchInput() {
            return this.searchable && (this.hasSingleSelectedSlot && (this.visibleSingleValue || this.visibleSingleValue === 0) ? this.isOpen : true);
        },
    },
};
